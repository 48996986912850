<template>
<div>
  <base-section-heading title="Klaim Progress"/>
  <v-timeline>
    <v-timeline-item
      v-for="n in 4"
      :key="n"
      large
    >
      <template v-slot:icon>
        <v-avatar>
          <img src="https://i.pravatar.cc/64">
        </v-avatar>
      </template>
      <template v-slot:opposite>
        <span>Tus eu perfecto</span>
      </template>
      <v-card class="elevation-2">
        <v-card-title class="text-h5">
          Lorem ipsum
        </v-card-title>
        <v-card-text>Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet nec ut, sed euismod convenire principes at. Est et nobis iisque percipit, an vim zril disputando voluptatibus, vix an salutandi sententiae.</v-card-text>
      </v-card>
    </v-timeline-item>
  </v-timeline>
  </div>
</template>

<script>
  export default {
    name: 'Account',
    data: () => ({
      check_login: null,
      dialog_forgot_password: false,
      //SnackBar
      snackbar: {
        dialog: false,
        color: 'success',
        text: '',
        timeout: 3000,
      },
      //Validation Form Users
      app_title: '',
      feeds:[],
      form_users: true,
      users_id: '',
      users_id_rules: [
        v => !!v || 'ID is required'
      ],
      users_password: '',
      users_password_rules: [
        v => !!v || 'Password is required'
      ],
      users_password_hint: false,
      form: {
        password: '',
        password_show: false,
        password_rules: [(v) => !!v || "Please fill Password"],
      }
    }),
    provide: {
      heading: { align: 'center' },
    },
    created () {
      this.FirstLoad()
    },
    methods: {
        FirstLoad () {
            if (this.$functions.UsersID === undefined) {
                this.$router.push({name: 'akun'})
            } else {
                this.app_title   = 'Login'
            }
        },
        UsersDetail (id) {
            let formdata = {
                    users_id: id,
                    users_employeef: 'Y',
                    order_by: 'users_id',
                    order_type: 'ASC',
                    limit: this.limit,
            }
            let param 	   = this.$functions.ParamPOST(formdata)
            this.$axios.post(this.$functions.UrlPOST('apiListUsersShort'),param,{
                headers: {
                    'Content-Type': 'text/plain; charset=ISO-8859-1'
            }
            })
            .then(response => {
                this.items = response.data
                localStorage.setItem('local_core_users_detail', JSON.stringify(this.items))
            })
            .catch(e => {
                this.SnackBar(true, 'error', e, 3000)
            })
        },
        SnackBar (dialog, color, text, timeout) {
            this.snackbar.dialog  = dialog
            this.snackbar.color   = color
            this.snackbar.text    = text
            this.snackbar.timeout = timeout
        },
    },
  }
</script>
